import React, { useEffect } from 'react';
import { onBlankLayout } from 'store/slices/themeSlice';
import { useDispatch } from 'react-redux';
import {useLocation, useNavigate, useParams} from "react-router-dom";

const AppRoute = ({ component: Component, routeKey, blankLayout, ...props }) => {

	const dispatch = useDispatch()

	let location = useLocation();
	let navigate = useNavigate();
	let params = useParams();

	useEffect(() => {
		const isBlank = !!blankLayout
		dispatch(onBlankLayout(isBlank))

	}, [blankLayout])

	return (
		<Component {...props} router={{ location, navigate, params }} />
	)
}

export default AppRoute
